import {PRODUCT_SEARCH_PROVIDERS, DEFAULT_PRODUCT_SEARCH_PROVIDER}  from "./branding"

const TRACKING_REG_EXP_FORMATS = {
    usps: [
        /(\b\d{30}\b)|(\b91\d+\b)|(\b\d{20}\b)/,
        /(\b\d{30}\b)|(\b91\d+\b)|(\b\d{20}\b)|(\b\d{26}\b)| ^E\D{1}\d{9}\D{2}|^9\d{15,21}| ^91[0-9]+| ^[A-Za-z]{2}[0-9]+US/i,
        /^E\D{1}\d{9}\D{2}|^9\d{15,21}/,
        /^91[0-9]+/,
        /^[A-Za-z]{2}[0-9]+US/,
        /(\b\d{30}\b)|(\b91\d+\b)|(\b\d{20}\b)|(\b\d{26}\b)| ^E\D{1}\d{9}\D{2}|^9\d{15,21}| ^91[0-9]+| ^[A-Za-z]{2}[0-9]+US/i
    ],
    ups: [
        /\b(1Z ?[0-9A-Z]{3} ?[0-9A-Z]{3} ?[0-9A-Z]{2} ?[0-9A-Z]{4} ?[0-9A-Z]{3} ?[0-9A-Z]|[\dT]\d\d\d ?\d\d\d\d ?\d\d\d)\b/,
        /^[kKJj]{1}[0-9]{10}/
    ],
    fedex: [
        /(\b96\d{20}\b)|(\b\d{15}\b)|(\b\d{12}\b)/,
        /\b((98\d\d\d\d\d?\d\d\d\d|98\d\d) ?\d\d\d\d ?\d\d\d\d( ?\d\d\d)?)\b/,
        /^[0-9]{15}$/
    ],
    dhl: [
        /^[0-9]{10,11}/
    ]
}

function findProductProvider(query,currentEngine) {
    if (!query && !currentEngine)
        return DEFAULT_PRODUCT_SEARCH_PROVIDER;
    let q=query.replace(/ /g,'');
    let found=false;
    let engine=currentEngine;
    for (const key in PRODUCT_SEARCH_PROVIDERS) {
        if (TRACKING_REG_EXP_FORMATS[key])
        {
            for (const reg_f in TRACKING_REG_EXP_FORMATS[key]) {
                if (new RegExp(TRACKING_REG_EXP_FORMATS[key][reg_f]).test(q))
                {
                    found=true;
                    engine=key;
                    break;
                }
            }
            if (found)
                break;
        }
    }
    return engine;
}


export {
    TRACKING_REG_EXP_FORMATS,
    findProductProvider
}