const defaultBranding = {
    // type of product - eg, "forms"
    ProductType: "Package Tracker Pro Search",
    // menu text to select product search tab - eg, "Forms Search"
    SearchLabel: "Search Package",
    // meta keyword specified in manifest (might not be the same as product type) - eg, "forms"
    MetaSearchKeyword: "Track",
    // example searches on first run - eg, "Taxes, Immigration, US"
    SearchExample: "EC 000 000 000 US",
    // eg, "Search form titles or tags"
    AutocompletePlaceholder: "Enter tracking number",
    // eg, "Search our database for forms by typing in a form query in the search box above."
    IntroLI1: "Search your shipment tracking number and get up to date status.",
    // eg, "You can also browse our forms database by 'tag' located on the left side menu of the page."
    IntroLI2: "Smart Search ! The extension utilizes built in rules to align  tracking number with correct carrier." ,
    FirstRunSettings: false,
}

const windowBranding = window.branding || {};

const PRODUCT_SEARCH_PROVIDERS = {
    ptpro: {
        name: "Auto Select Courier",
        id: "ptpro",
        isDefault: false,
        url: "",
    },
    usps: {
        name: "USPS",
        id: "usps",
        isDefault: false,
        url: "https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLabels={{QUERY}}",
    },
    ups: {
        name: "UPS",
        id: "ups",
        isDefault: false,
        url: "https://www.ups.com/track?loc=&tracknum={{QUERY}}",
    },
    fedex: {
        name: "Fedex",
        id: "fedex",
        isDefault: false,
        url: "https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber={{QUERY}}&cntry_code=us&locale=en_US",
    },
    dhl: {
        name: "DHL",
        id: "dhl",
        isDefault: false,
        url: "http://www.dhl.com/en/express/tracking.html?AWB={{QUERY}}&brand=DHL",
    },
}

const altSearchProvidersUrls = function(query) {
    const altProviders = Object.entries(PRODUCT_SEARCH_PROVIDERS).filter(([key, value]) =>  !value.isDefault);
    return altProviders.map(([key, value]) => {
        return {
            name: value.name,
            url: value.url.replace("{{QUERY}}", query),
        }
    });
}

const DEFAULT_PRODUCT_SEARCH_PROVIDER = PRODUCT_SEARCH_PROVIDERS["ptpro"].id;

function getBranding(){
    return {
        ...defaultBranding,
        ...windowBranding,
    }
}

export {
    getBranding,
    PRODUCT_SEARCH_PROVIDERS,
    altSearchProvidersUrls,
    DEFAULT_PRODUCT_SEARCH_PROVIDER
}
